// app/javascript/packs/nested-forms/removeFields.js
class removeFields {
    // This executes when the function is instantiated.
    constructor() {
      this.iterateLinks()
    }
  
    iterateLinks() {
      // Use event delegation to ensure any fields added after the page loads are captured.
      document.addEventListener('click', e => {
        if (e.target && (e.target.className == 'remove_fields' || $('.remove_fields').has(e.target).length)) {
          this.handleClick(e.target, e)
          $('.add_fields').fadeTo(400, 1);
        }
      })
    }
  
    handleClick(link, e) {
      // Stop the function from executing if a link or event were not passed into the function.
      if (!link || !e) return
      // Prevent the browser from following the URL.
      e.preventDefault()
      // Find the parent wrapper for the set of nested fields.
      let fieldParent = link.closest('.nested-fields')
      
      $(fieldParent).slideUp("normal", function() {
        $(this).remove();
        link.classList.remove('disabled');
      })
    }
  }
  
  // Wait for turbolinks to load, otherwise `document.querySelectorAll()` won't work
  window.addEventListener('turbolinks:load', () => new removeFields())
  