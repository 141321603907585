$( document ).on('turbolinks:load', function() {
    $('html').css('overflow-y','auto');

    $(".hamburger").click(function(e) {
        e.preventDefault();
        $(this).parents().find('.show-mobile-menu').removeClass('hidden');
        $('html').css('overflow-y','hidden');
    });
    $(".hamburger-cross").click(function(e) {
        e.preventDefault();
        $(this).parents().find('.show-mobile-menu').addClass('hidden');
        $('html').css('overflow-y','auto');
    });
})
