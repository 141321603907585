import Rails from '@rails/ujs'; 

$(document).on('turbolinks:load', function() {

    if ($('.new_careers_request, .new_tellem_request, .new_contact_request').length > 0) { 
        initializeRecaptcha();
    }

    $('#new_careers_request input[type="submit"]').click(function(e) {
        e.preventDefault();
    
        submitRecaptchableForm('new_careers_request',
                            'recaptchaTokenField',
                           'careers');
        return false;
    });

    $('#new_tellem_request input[type="submit"]').click(function(e) {
        e.preventDefault();
    
        submitRecaptchableForm('new_tellem_request',
                            'recaptchaTokenField',
                           'tellem');
        return false;
    });

    $('#new_contact_request input[type="submit"]').click(function(e) {
        e.preventDefault();
    
        submitRecaptchableForm('new_contact_request',
                            'recaptchaTokenField',
                           'contact');
        return false;
    });
});

function initializeRecaptcha() {  
  var script = document.createElement("script");
  script.type = "text/javascript";
  script.src = "https://www.google.com/recaptcha/api.js?render=6LdbsCcdAAAAAF3leXps67rn7qJ3sY7FX447_7sT";
  document.getElementsByTagName("head")[0].appendChild(script);
  return false;
}

function submitRecaptchableForm(formId, tokenFieldId, action) {
  grecaptcha.ready(function() {
    grecaptcha.execute('6LdbsCcdAAAAAF3leXps67rn7qJ3sY7FX447_7sT', { action: action }).then(function(token) {
        document.getElementById(tokenFieldId).value = token;
      var recaptchaedForm = document.querySelector('#' + formId);

      var isRemote = $(recaptchaedForm).data('remote');
      if (isRemote) {
        Rails.fire(recaptchaedForm, 'submit');
      }
      else {
        recaptchaedForm.submit();
      }
    });
  });
}
