/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import 'jquery'
//import "channels"
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"

import "@fortawesome/fontawesome-pro/js/all"
import "@fortawesome/fontawesome-pro/css/all"

import '../../stylesheets/application.scss';

require('./plugins/popper.min.js')
require('./plugins/bootstrap.bundle.min.js')
require('./plugins/TweenMax.min.js')
require('./plugins/jquery.toggle.min.js')
require('./plugins/custom.js')
require('./file_upload.js');
require('./recaptcha.js.erb');
require('./tooltip');
require("./nested-forms/addFields.js")
require("./nested-forms/removeFields.js")
require("./toggle-video.js");

Rails.start()
Turbolinks.start()
ActiveStorage.start()

require.context('../../images', true)

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../../images', true)
const imagePath = (name) => images(name, true)


