function init() {
    if ($('.file-input-custom').length) {

        $('.add_fields').addClass('disabled');

        $('.add_fields').on('click', function() {    
            $('input[type="file"]').each(function(f) { 
                if (!$(this).val()) { 
                    $('.add_fields').addClass('disabled');
                    return;
                }
            })
        })
    
		$('body').on('change', 'input[type="file"]', function() {
            var allFiles = [];
            var MAX_UPLOAD_SIZE = 2000000;
            $("#file_validation_message").slideUp();

            $('input[type="file"]').each(function(f) {
                if ($('input[type="file"]').get(f).files[0] !== undefined) {
                    allFiles.push($('input[type="file"]').get(f).files[0]);
                } else if (!$(this).val()) {
                    $('.add_fields').addClass('disabled');
                }
            });

            if (!$(this).val()) {
                resetInput(this);
                return;
            }
            if ($(this).prop('files')[0].size > MAX_UPLOAD_SIZE) {
                fileSizeTooBig(this);
                return;
            }
            if (!validateSize(allFiles, MAX_UPLOAD_SIZE)) {
                var lastElement = $('input[type="file"]:last').filter((i, el) => el.value ).last()
                fileSizeTooBig(lastElement);
                return;
            } 

            var input = $(this),
                numFiles = input.get(0).files ? input.get(0).files.length : 1,
                label = input.val().replace(/\\/g, '/').replace(/.*\//, '');
            input.trigger('fileselect', [numFiles, label]);
        });

        $('form').on("submit", function(e) { 
            $("input[type='file']").prop('readonly',true);
            $(this).find("input[type='submit']").prop('readonly',true);
            $(this).find("input[type='submit']").val("Submitting...");
        });

        $('body').on('fileselect', ':file', function(event, numFiles, label) {
            
            if ($(this).prop('readonly')) {
                return;
            }

            console.log("hello")
            console.log($(this).val());

            var input = $(this).parents('.input-group').find('.file-display'),
                log = numFiles > 1 ? numFiles + ' files selected' : label;
    
            if (input.length && numFiles) {
                input.html(log);
                $('.add_fields').removeClass('disabled');
            } else {
                input.html(`No files chosen.`);
                $('.add_fields').addClass('disabled');
            }
        });
	}
}

function fileSizeTooBig(element) {
    $("#file_validation_message .message").html("Total file upload size should be less than 2MB.");
    $("#file_validation_message").slideDown();
    resetInput(element);
    $('.add_fields').addClass('disabled');
}

function resetInput(element) {
    $(element).val("");
    $(element).parents('.input-group').find('.file-display').html('No files chosen.')
}

function validateSize(files, maxSize) {
    var totalSize = 0;
          
    for (var i = 0; i < files.length; i++) {     
      totalSize += files[i].size;

      if (totalSize > maxSize) {
          return false;
      }
    }

    return true;
}

function showImage(input) {
	if (input.files && input.files[0]) {
		var reader = new FileReader();

		reader.onload = function (e) {
			$('#preview').attr('src', e.target.result)
		};

		reader.readAsDataURL(input.files[0]);
	}
}

// need to initialize file upload on javascript escape
init();

$(document).on('turbolinks:load', function() { 
    init();
});